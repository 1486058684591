// extracted by mini-css-extract-plugin
export var alignCenter = "landing-module--alignCenter--BAsxg";
export var backgroundGreen = "landing-module--backgroundGreen--BL8ui";
export var bannerImage = "landing-module--bannerImage--z9S9Y";
export var bullet = "landing-module--bullet--btRGH";
export var bulletText = "landing-module--bulletText--d6may";
export var h2 = "landing-module--h2--3S3B3";
export var h3 = "landing-module--h3--6Ebma";
export var iconWrapper = "landing-module--iconWrapper--awzvL";
export var image = "landing-module--image--J5KMh";
export var inner = "landing-module--inner--jE4bf";
export var layer = "landing-module--layer--n+TW2";
export var raddningsverket = "landing-module--raddningsverket--TzdeN";
export var sectionPaddingBottom = "landing-module--sectionPaddingBottom--dpPO3";
export var sectionPaddingTop = "landing-module--sectionPaddingTop--znYFE";
export var serviceLink = "landing-module--serviceLink--Urham";
export var slickSlider = "landing-module--slickSlider--v5Mj0";
export var slide = "landing-module--slide--XeT+2";
export var slideOuter = "landing-module--slideOuter--+C9qf";
export var subheading = "landing-module--subheading--Fhiov";
export var text = "landing-module--text--LAXTs";
export var textBox__inner = "landing-module--textBox__inner--QWYHr";
export var textBox_wrapper = "landing-module--textBox_wrapper--0p6QO";
export var textDarkblue = "landing-module--textDarkblue--OFOto";
export var twoColGrid = "landing-module--twoColGrid--gGXG+";
export var usage = "landing-module--usage--Ui3wH";
export var usageBox = "landing-module--usageBox--SwdeR";
export var usageBoxInformation = "landing-module--usageBoxInformation--w8s+p";
export var usageBoxes = "landing-module--usageBoxes--YtwYr";