import React, { useState } from "react";
import { TranslationProvider } from "src/contexts/translation.context";
import { TranslationService } from "src/services/translation.service";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import Check from "@/assets/icons/check.inline.svg";
import Calendar from "@/assets/icons/calendar.inline.svg";
import Clip from "@/assets/icons/clip.inline.svg";
import EyeCrossed from "@/assets/icons/eye-crossed.inline.svg";
import { StaticImage } from "gatsby-plugin-image";
import Lr from "@/assets/icons/lr.inline.svg";

import Footer from "@/src/components/footer/footer";
import Header from "@/src/components/header/header";
import * as styles from "./landing.module.scss";
import { Link } from "gatsby";

export default function LandingPage({ pageContext }: any) {
  const [translation] = useState(new TranslationService(pageContext));
  const sliderSection = pageContext.data.content.sections.find(
    (s) => s.name === "zigZagSlider"
  );
  const slides = sliderSection.slides
    .map((s) => {
      return s.bullets.map((b) => {
        return {
          heading: s.heading,
          image: s.image,
          icon: b.icon,
          text: b.text,
        };
      });
    })
    .flat();

  const slickSettings = {
    className: styles["slickSlider"],
    dots: true,
    infinite: true,
    speed: 500,
    slidesToScroll: 1,
    slidesToShow: 4,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 1000,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          dots: true,
          infinite: true,
          speed: 500,
          slidesToScroll: 1,
          slidesToShow: 1,
        },
      },
    ],
  };

  return (
    <TranslationProvider value={translation}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{translation.translate("landing.title")}</title>
        <meta
          name="description"
          content={translation.translate("landing.description")}
        />
        <meta http-equiv="content-language" content={translation.lang} />
      </Helmet>

      <Header unauthenticated={true} pageContext={pageContext}></Header>

      <div className="banner">
        <StaticImage
          src="../../../assets/images/header.jpg"
          alt="Background image"
          className={styles.bannerImage}
        />
        <div className={styles.layer}>
          <h1>{translation.translate("landing.banner.heading")}</h1>

          <p className={styles.subheading}>
            {translation.translate("landing.banner.text")}
          </p>
        </div>
      </div>

      <div id="usage"></div>
      <div className={styles.usage}>
        <h2 className={styles.h2}>
          {translation.translate("landing.slider.heading")}:
        </h2>

        <Slider {...slickSettings}>
          <div className={styles.slideOuter}>
            <div className={styles.slide}>
              <div className={styles.image}>
                <StaticImage
                  src="../../../assets/images/iPhone_12_Pro.png"
                  width={325}
                  alt="Iphone 12 pro"
                />
              </div>
              <h3 className={styles.h3}>
                {translation.translate("landing.slider.slideHeading")}
              </h3>
              <div className={styles.text}>
                {translation.translate("landing.slider.slide1.text")}
              </div>
            </div>
          </div>
          <div className={styles.slideOuter}>
            <div className={styles.slide}>
              <div className={styles.image}>
                <StaticImage
                  src="../../../assets/images/iPhone_12_Pro.png"
                  width={325}
                  alt="Iphone 12 pro"
                />
              </div>
              <h3 className={styles.h3}>
                {translation.translate("landing.slider.slideHeading")}
              </h3>
              <div className={styles.text}>
                {translation.translate("landing.slider.slide2.text")}
              </div>
            </div>
          </div>
        </Slider>

        <div className={styles.usageBoxes}>
          <div className={styles.usageBox}>
            <StaticImage
              src="../../../assets/images/iPhone_12_Pro.png"
              width={325}
              alt="Iphone 12 pro"
            />

            <div className={styles.usageBoxInformation}>
              <h3 className={styles.h3}>
                {translation.translate("landing.slider.slideHeading")}
              </h3>

              <div className={styles.bullet}>
                <div className={styles.iconWrapper}>
                  {getIcon(
                    translation.translate("landing.slider.bullet1.icon")
                  )}
                </div>

                <div className={styles.bulletText}>
                  {translation.translate("landing.slider.bullet1.text")}
                </div>
              </div>
              <div className={styles.bullet}>
                <div className={styles.iconWrapper}>
                  {getIcon(
                    translation.translate("landing.slider.bullet2.icon")
                  )}
                </div>
                <div className={styles.bulletText}>
                  {" "}
                  {translation.translate("landing.slider.bullet2.text")}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.textBox_wrapper}>
        <div className="inner">
          <div className={styles.textBox__inner}>
            <h2>{translation.translate("landing.textBlock.heading")}</h2>
            <p>
              <span>{translation.translate("landing.textBlock.line1")}</span>
            </p>
            <p>
              <span>{translation.translate("landing.textBlock.line2")}</span>
            </p>
            <p>
              <span>
                {translation.translate("landing.textBlock.line3")}{" "}
                <Link
                  className={styles.serviceLink}
                  to={`${translation.linkPrefix}/companies-and-associations`}
                >
                  {translation.translate("menu.companies_and_associations")}
                </Link>
                .{" "}
              </span>
            </p>
          </div>
        </div>
      </div>

      <div
        className={`${styles.sectionPaddingBottom} ${styles.sectionPaddingTop} ${styles.backgroundGreen}`}
      >
        <div className={`${styles.inner} ${styles.twoColGrid}`}>
          <h2 className={`${styles.subheading} ${styles.textDarkblue}`}>
            {translation.translate("landing.cooperate.heading")}:
          </h2>
          <div className={`${styles.alignCenter}`}>
            <Lr />
            <StaticImage
              src="../../../assets/images/raddningsverket.png"
              alt="Mariehamns Räddningsverk"
              className={styles.raddningsverket}
            />
          </div>
        </div>
      </div>

      <Footer pageContext={pageContext}></Footer>
    </TranslationProvider>
  );
}

const getIcon = (icon) => {
  switch (icon) {
    case "calendar":
      return <Calendar></Calendar>;
    case "clip":
      return <Clip></Clip>;
    case "eye-crossed":
      return <EyeCrossed></EyeCrossed>;
    default:
      return <Check></Check>;
  }
};
